import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

const EmailDialog = ({
  couponInfo,
  storeName,
  storeId,
  emailAddress,
  name,
  confirmSend,
  open,
  onClose,
}) => {
  const [to, setTo] = useState(emailAddress);
  const [replyTo, setReplyTo] = useState(
    `catering${storeId}@mendocinofarms.com`
  );
  const [subject, setSubject] = useState(
    `Don't Forget Your Free Treat-It's Waiting for You!`
  );
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const couponSelection1 = couponInfo[0]?.couponSelection || 'item';
    const couponSelection2 = couponInfo[1]?.couponSelection || 'item';
    // const firstName = name.split(' ')[0];

    // Calculate the expiration date
    const dateSent = new Date(couponInfo[0]?.dateSent);
    const expirationDate = new Date(dateSent);
    expirationDate.setDate(expirationDate.getDate() + 90);

    // Format expiration date
    const expirationDateString = `${
      expirationDate.getMonth() + 1
    }/${expirationDate.getDate()}/${expirationDate.getFullYear()}`;

    const defaultBody = `Hi ${name},

Just following up to make sure you didn’t miss out on your treat! We’d hate for that delicious FREE ${couponSelection1} to go uneaten.

Here’s your code again, ready and waiting for your next catering order. It expires ${expirationDateString}, so order soon!

FREE ${couponSelection1}: {{offer_code}}

We’re excited for the chance to show you the true Mendo experience and make it right this time. Got any questions? I’m just a reply away!

Best,
Mendo ${storeName} Team

Each catering code is valid towards your next online catering  order. The code is valid only for orders placed online at https://mendocinofarmscatering.olo.com/ . Not valid for in-store or 3rd party orders. Limit one code per order. Code may not be used multiple times and has no cash value. Other restrictions may apply.
`;

    const multiCouponBody = `Hi ${name},

Just following up to make sure you didn’t miss out on your treat! We’d hate for these delicious FREE ${couponSelection1} and FREE ${couponSelection2} offer codes to go uneaten.

Here’s your code again, ready and waiting for your next catering order. It expires ${expirationDateString}, so order soon!

FREE ${couponSelection1}: {{offer_code}}
FREE ${couponSelection2}: {{offer_code}}

We’re excited for the chance to show you the true Mendo experience and make it right this time. Got any questions? I’m just a reply away!

Best,
Mendo ${storeName} Team

Each catering code is valid towards your next online catering  order. The code is valid only for orders placed online at https://mendocinofarmscatering.olo.com/ . Not valid for in-store or 3rd party orders. Limit one code per order. Code may not be used multiple times and has no cash value. Other restrictions may apply.`;

    setBody(couponInfo.length > 1 ? multiCouponBody : defaultBody);
  }, [couponInfo, name, storeName]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStatus('');

    try {
      await confirmSend(to, replyTo, subject, body);
      setStatus('Email sent successfully!');
    } catch (error) {
      setStatus(`Error sending email: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth='lg' open={open} onClose={onClose}>
      <DialogTitle>Email Offer Code</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {status ? (
            <p style={{ fontSize: 16 }}>{status}</p>
          ) : (
            <React.Fragment>
              <TextField
                disabled
                autoFocus
                margin='dense'
                label='To'
                type='email'
                fullWidth
                variant='outlined'
                value={to}
                onChange={(e) => setTo(e.target.value)}
                required
              />
              <TextField
                disabled
                autoFocus
                margin='dense'
                label='Reply To'
                type='replyTo'
                fullWidth
                variant='outlined'
                value={replyTo}
                onChange={(e) => setReplyTo(e.target.value)}
                required
              />
              <TextField
                margin='dense'
                label='Subject'
                type='text'
                fullWidth
                variant='outlined'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <TextField
                margin='dense'
                label='Body'
                type='text'
                fullWidth
                variant='outlined'
                multiline
                value={body}
                onChange={(e) => setBody(e.target.value)}
                required
              />
            </React.Fragment>
          )}
          <DialogActions>
            <Button onClick={onClose} color='error'>
              Cancel
            </Button>
            {status === 'Email sent successfully!' ? (
              <Button color='primary' onClick={onClose}>
                Close
              </Button>
            ) : (
              <Button type='submit' color='primary' disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Send Email'}
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EmailDialog;
