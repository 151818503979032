import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  InputBase,
  NativeSelect,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './CouponDialog.css';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

// Reusable component for selecting coupon types and comments
const CouponSelection = ({
  label,
  couponValue,
  commentValue,
  additionalCommentValue,
  couponError,
  commentError,
  onCouponChange,
  onCommentChange,
  onAdditionalCommentChange,
  commentOptions,
}) => (
  <>
    <FormControl fullWidth margin='normal'>
      <InputLabel>{label}</InputLabel>
      <NativeSelect
        input={<BootstrapInput />}
        value={couponValue}
        onChange={onCouponChange}
      >
        <option value='' />
        <option value='Large Deli Side'>Large Deli Side</option>
        <option value='Large Specialty Salad'>Large Specialty Salad</option>
        <option value='Mini Sandwich Tray'>Mini Sandwich Tray</option>
      </NativeSelect>
      {couponError && <p style={{ color: 'red' }}>{couponError}</p>}
    </FormControl>

    <FormControl fullWidth margin='normal'>
      <InputLabel>Reason for {label}</InputLabel>
      <NativeSelect
        input={<BootstrapInput />}
        value={commentValue}
        onChange={onCommentChange}
      >
        <option value='' />
        {commentOptions.map((option, idx) => (
          <option key={idx} value={option}>
            {option}
          </option>
        ))}
      </NativeSelect>
      {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
    </FormControl>

    <FormControl fullWidth margin='normal'>
      <TextField
        label={`Additional Comments for ${label}`}
        value={additionalCommentValue}
        onChange={onAdditionalCommentChange}
        multiline
        rows={4}
      />
    </FormControl>
  </>
);

const CouponDialog = ({
  open,
  handleGenerateCoupons,
  invoiceNumber,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [codeCount, setCodeCount] = useState(1);
  const [couponSelections, setCouponSelections] = useState({ 1: '', 2: '' });
  const [comments, setComments] = useState({ 1: '', 2: '' });
  const [additionalComments, setAdditionalComments] = useState({
    1: '',
    2: '',
  });
  const [errors, setErrors] = useState({});

  const commentOptions = [
    'Late Delivery',
    'Order Not Prepared On Time',
    'Missing Item(s)',
    'Incorrect Item(s)',
    'Foreign Object in Food',
    'Rude Delivery Driver',
    'Other',
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    try {
      const resultArray = [1, 2]
        .filter((key) => couponSelections[key]?.length > 0)
        .map((key) => ({
          couponSelection: couponSelections[key],
          comment: comments[key],
          additionalComment: additionalComments[key],
          invoiceNumber,
        }));
      console.log(resultArray);
      await handleGenerateCoupons(resultArray);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!couponSelections[1]) {
      newErrors.coupon1 =
        'Please select an offer code type for the first selection.';
    }
    if (codeCount === 2 && !couponSelections[2]) {
      newErrors.coupon2 =
        'Please select an offer code type for the second selection.';
    }
    if (!comments[1]) {
      newErrors.comment1 = 'Please provide a reason for the first selection.';
    }
    if (codeCount === 2 && !comments[2]) {
      newErrors.comment2 = 'Please provide a reason for the second selection.';
    }
    return newErrors;
  };

  const handleCodeCountChange = (event) => {
    setCodeCount(Number(event.target.value));
    setCouponSelections({ 1: '', 2: '' });
    setComments({ 1: '', 2: '' });
    setAdditionalComments({ 1: '', 2: '' });
    setErrors({});
  };

  const handleFieldChange = (setter) => (index) => (event) => {
    setter((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
    setErrors((prev) => ({
      ...prev,
      [`coupon${index}`]: '',
      [`comment${index}`]: '',
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Offer Code Management</DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 0 }}>
        <form onSubmit={handleSubmit}>
          <FormControl component='fieldset' margin='normal' fullWidth>
            <Typography variant='subtitle1'>
              How many codes would you like?
            </Typography>
            <RadioGroup
              aria-label='code count'
              value={codeCount}
              onChange={handleCodeCountChange}
            >
              <FormControlLabel value={1} control={<Radio />} label='1 Code' />
              <FormControlLabel value={2} control={<Radio />} label='2 Codes' />
            </RadioGroup>
          </FormControl>

          {/* First selection */}
          <CouponSelection
            label='Offer Code 1'
            couponValue={couponSelections[1]}
            commentValue={comments[1]}
            additionalCommentValue={additionalComments[1]}
            couponError={errors.coupon1}
            commentError={errors.comment1}
            onCouponChange={handleFieldChange(setCouponSelections)(1)}
            onCommentChange={handleFieldChange(setComments)(1)}
            onAdditionalCommentChange={handleFieldChange(setAdditionalComments)(
              1
            )}
            commentOptions={commentOptions}
          />

          {/* Second selection if needed */}
          {codeCount === 2 && (
            <CouponSelection
              label='Offer Code 2'
              couponValue={couponSelections[2]}
              commentValue={comments[2]}
              additionalCommentValue={additionalComments[2]}
              couponError={errors.coupon2}
              commentError={errors.comment2}
              onCouponChange={handleFieldChange(setCouponSelections)(2)}
              onCommentChange={handleFieldChange(setComments)(2)}
              onAdditionalCommentChange={handleFieldChange(
                setAdditionalComments
              )(2)}
              commentOptions={commentOptions}
            />
          )}

          <DialogActions>
            <Button onClick={onClose} color='error'>
              Cancel
            </Button>
            {status === 'Offer code sent successfully!' ? (
              <Button color='primary' onClick={onClose}>
                Close
              </Button>
            ) : (
              <Button type='submit' color='primary' disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  `Generate Offer Code${codeCount === 2 ? `s` : ''}`
                )}
              </Button>
            )}
          </DialogActions>
        </form>
        {status && <p>{status}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default CouponDialog;
