import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Accordion,
  AccordionDetails,
  Box,
  TextField,
  InputBase,
  Backdrop,
  NativeSelect,
  CircularProgress,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Table from './Table/Table';
import FilterActionBar from './FilterActionBar/FilterActionBar';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const columnMap = {
  storeName: 'Store',
  oloRef: 'Original Order',
  timeWanted: 'Original Time Wanted',
  reason: 'Reason',
  menuItem: 'Offer',
  lastSent: 'Last Sent',
  name: 'Booking Client Name',
  emailAddress: 'Booking Client Email',
  phoneNumber: 'Phone',
  paymentType: 'Payment',
  redeemed: 'Used / Not Used',
  redeemedOrder: 'Redeemed Order',
};

const CouponContent = ({
  handleViewInvoice,
  firebaseUser,
  role,
  formData,
  selectedOloStore,
  setSelectedOloStore,
  stores,
}) => {
  const [coupons, setCoupons] = useState([]);
  const [couponTotalCount, setCouponTotalCount] = useState(0);
  const [couponPage, setCouponPage] = useState(0);
  const [couponRowsPerPage, setCouponRowsPerPage] = useState(10);
  const [couponOrder, setCouponOrder] = useState('desc');
  const [couponOrderBy, setCouponOrderBy] = useState('timeWanted');
  const [searchField, setSearchField] = useState('orderNumber');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState();

  // useEffect(() => {
  //   handleReset();
  // }, []);

  useEffect(() => {
    if (firebaseUser.getIdToken && formData) {
      if (searchQuery.length > 0 || startDate || endDate) {
        handleSearch();
      } else if (selectedOloStore) {
        handleReset();
      }
    }
  }, [selectedOloStore, firebaseUser, couponPage, couponRowsPerPage]);

  const handleClear = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    handleReset();
  };

  const handleChangeSelectedStore = (e) => {
    setSelectedOloStore(e.target.value);
  };
  const handleReset = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      let url = `/hd-api/olo/offer-codes?page=${couponPage}&limit=${couponRowsPerPage}&order=${couponOrder}&orderBy=${couponOrderBy}&storeId=${selectedOloStore}&role=${formData.role}&user=${firebaseUser.email}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setCoupons(response.data.coupons);
      setCouponTotalCount(response.data.totalCount);
      setCouponRowsPerPage(response.data.rowsPerPage);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching coupons from db:', error);
      setCoupons([]);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      let url = `/hd-api/olo/offer-codes?page=${couponPage}&limit=${couponRowsPerPage}&order=${couponOrder}&orderBy=${couponOrderBy}&storeId=${selectedOloStore}&role=${formData.role}&user=${firebaseUser.email}&searchField=${searchField}`;

      if (searchField === 'lastSent' || searchField === 'timeWanted') {
        if (startDate) {
          url += `&startDate=${startDate?.toISOString()}`;
        }
        if (endDate) {
          url += `&endDate=${endDate?.toISOString()}`;
        }
      } else if (searchQuery?.length > 0) {
        url += `&searchQuery=${searchQuery}`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setCoupons(response.data.coupons);
      setCouponTotalCount(response.data.totalCount);
      setCouponRowsPerPage(response.data.rowsPerPage);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching coupons from db:', error);
      setCoupons([]);
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      const fileName = prompt(
        'Enter the file name for the export (without extension):'
      );
      if (!fileName) {
        alert('Export cancelled. Please provide a valid file name.');
        return;
      }
      setLoading(true);
      let apiUrl = `/hd-api/olo/offer-codes/export?user=${firebaseUser.email}&role=${formData.role}&searchField=${searchField}&storeId=${selectedOloStore}`;

      if (
        searchField === 'timeWanted' ||
        searchField === 'timePlaced' ||
        searchField === 'timeWanted'
      ) {
        if (startDate) {
          apiUrl += `&startDate=${startDate?.toISOString()}`;
        }
        if (endDate) {
          apiUrl += `&endDate=${endDate?.toISOString()}`;
        }
      } else {
        apiUrl += `&searchQuery=${searchQuery}`;
      }

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      const response = await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to export orders:', error);
    }
  };

  const handleRequestSort = (event, property, table) => {
    const isAsc = couponOrderBy === property && couponOrder === 'asc';
    setCouponOrder(isAsc ? 'desc' : 'asc');
    setCouponOrderBy(property);
  };

  const handleChangeSearchQuery = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleChangeSearchField = (e) => {
    const { value } = e.target;
    setSearchQuery('');
    setStartDate();
    setEndDate();
    setSearchField(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'timeWanted' || orderBy === 'timePlaced') {
      return new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, comparator) => {
    if (array?.length > 0) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    } else {
      return [];
    }
  };

  const handleChangecouponPage = (event, newPage) => {
    setCouponPage(newPage);
  };

  const handleChangecouponRowsPerPage = (event) => {
    setCouponRowsPerPage(parseInt(event.target.value, 10));
    setCouponPage(0);
  };

  let showStoreFilter = false;
  if (
    role === 'DO' ||
    role === 'Basecamp' ||
    (role === 'Direct' && !formData.selectedStore)
  ) {
    showStoreFilter = true;
  }
  let searchInput;
  if (searchField === 'lastSent' || searchField === 'timeWanted') {
    searchInput = (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText='Start Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText='End Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
      </div>
    );
  } else if (searchField === 'redeemed') {
    searchInput = (
      <FormControl size='small' focused>
        <InputLabel id='search-field-label'>Used/Not Used</InputLabel>
        <NativeSelect
          value={searchQuery}
          onChange={handleChangeSearchQuery}
          label='Used/Not Used'
          input={<BootstrapInput />}
        >
          <option value=''></option>
          <option value='used'>Used</option>
          <option value='notused'>Not Used</option>
        </NativeSelect>
      </FormControl>
    );
  } else {
    searchInput = (
      <TextField
        sx={{ backgroundColor: 'white', marginTop: 1 }}
        size='small'
        value={searchQuery}
        name='searchQuery'
        onChange={handleChangeSearchQuery}
        onKeyPress={handleKeyPress}
      />
    );
  }

  return (
    <div>
      <FilterActionBar
        firebaseUser={firebaseUser}
        searchField={searchField}
        handleChangeSearchField={handleChangeSearchField}
        handleExport={handleExport}
        searchInput={searchInput}
        handleSearch={handleSearch}
        handleClear={handleClear}
        showStoreFilter={showStoreFilter}
        selectedOloStore={selectedOloStore}
        handleChangeSelectedStore={handleChangeSelectedStore}
        stores={stores}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Accordion
          defaultExpanded
          style={{ width: '100%', backgroundColor: '#f8f8f8', marginBottom: 0 }}
        >
          <AccordionDetails>
            <Table
              order={couponOrder}
              totalCount={couponTotalCount}
              orderBy={couponOrderBy}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'coupon')
              }
              handleViewInvoice={handleViewInvoice}
              onPageChange={handleChangecouponPage}
              onRowsPerPageChange={handleChangecouponRowsPerPage}
              rowsPerPage={couponRowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={couponPage}
              columnMap={columnMap}
              coupons={coupons}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default CouponContent;
